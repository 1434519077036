@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Inter' sans-serif;
}

#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
}

.site-layout .site-layout-background {
    background: rgb(255, 255, 255);
}

.ant-dropdown-link {
    background-color: none;
}

.ant-table-thead .ant-table-cell {
    background-color: #3951a3;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  thead:hover {
      background: #4966c7 !important;
  }

  thead:active {
    background: #4966c7 !important;
}

thead:focus {
    background: #4966c7 !important;
}

  .ant-table-tbody > tr.ant-table-row> td {
    font-size: 15px;
}
