.design {
    display: block;
    position: relative;
    top: -5px;
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 895 450" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(254, 73, 94, 1)" d="M 0 200 C 454 200 454 110 908 110 L 908 110 L 908 0 L 0 0 Z" stroke-width="0"></path> <path fill="rgba(254, 73, 94, 1)" d="M 907 110 C 1351 110 1351 79 1795 79 L 1795 79 L 1795 0 L 907 0 Z" stroke-width="0"></path> </svg>');
    background-size: cover;
    background-repeat: no-repeat;
}

.table-cell-body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: bolder;
    color: #464C6B;
    padding-top: 15px;
    padding-bottom: 15px;
}

.button {
    font-family: 'Inter', sans-serif;
    margin-top: 20px;
    margin-bottom: 15px;
}

.card {
    box-shadow: 0 3px 6px 0 #0000001a;
    border: none;
    border-radius: 6px;
    margin-bottom: 15px;
}

.data-desc {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #495172;
    margin-bottom: 15px;
}

.data-info {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    color: #8c91a4;
}

.data-val {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    color: #495172;
}

.sensor-card-desc {
    text-align: center;
    color: #495172;
    padding-top: 10px;
    padding-bottom: 3px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
}

.sensor-card-stats {
    font-family: 'Inter', sans-serif;
}

.page-header {
    padding: 7px 7px 7px 15px;
    font-family: 'Inter', sans-serif;
    /* background: #edf5fd; */
    border: none;
}

.page-custom-header {
    font-family: 'Inter', sans-serif;
    /* background: #EDF5FD; */
    border: none;
    margin-bottom: 15px;
}

.sensor-page-header {
    margin-top: 15px;
    padding: 7px 7px 7px 15px;
    font-family: 'Inter', sans-serif;
    background: #ffffff;
    border: none;
    box-shadow: 0 3px 6px 0 #0000001a;
    border-radius: 4px;
}

.radio-group {
    margin-top: 15px;
}

.logo {
    text-align: center;
}
