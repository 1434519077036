@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
    font-family: 'Inter' sans-serif;
}

#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
}

.site-layout .site-layout-background {
    background: rgb(255, 255, 255);
}

.ant-dropdown-link {
    background-color: none;
}

.ant-table-thead .ant-table-cell {
    background-color: #3951a3;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  thead:hover {
      background: #4966c7 !important;
  }

  thead:active {
    background: #4966c7 !important;
}

thead:focus {
    background: #4966c7 !important;
}

  .ant-table-tbody > tr.ant-table-row> td {
    font-size: 15px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

.tagline {
    padding: 5rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-size: 30px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.partners {
    text-align: center;
    width: 100%;
}

.partners:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    text-decoration: none;
}

.img-container {
    display: flex;
}

.img-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 100%;
    min-height: 100%;
}

.form {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.form-container {
    margin: 100px auto;
    width: 1000px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    border-radius: 10px;
    height: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .form-content-left {
    background-color: #3A519F;
    color: white;
    border-radius: 10px 0 0 10px;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .form-content-right {
    border-radius: 0 10px 10px 0;
    position: relative;
    background-color: white;
    font-family: 'Inter', sans-serif;
    padding: 50px;
  }

  h1 {
    font-weight: 600;
    text-align: center;
    padding: 35px;
  }
.design {
    display: block;
    position: relative;
    top: -5px;
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 895 450" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(254, 73, 94, 1)" d="M 0 200 C 454 200 454 110 908 110 L 908 110 L 908 0 L 0 0 Z" stroke-width="0"></path> <path fill="rgba(254, 73, 94, 1)" d="M 907 110 C 1351 110 1351 79 1795 79 L 1795 79 L 1795 0 L 907 0 Z" stroke-width="0"></path> </svg>');
    background-size: cover;
    background-repeat: no-repeat;
}

.table-cell-body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: bolder;
    color: #464C6B;
    padding-top: 15px;
    padding-bottom: 15px;
}

.button {
    font-family: 'Inter', sans-serif;
    margin-top: 20px;
    margin-bottom: 15px;
}

.card {
    box-shadow: 0 3px 6px 0 #0000001a;
    border: none;
    border-radius: 6px;
    margin-bottom: 15px;
}

.data-desc {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #495172;
    margin-bottom: 15px;
}

.data-info {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    color: #8c91a4;
}

.data-val {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    color: #495172;
}

.sensor-card-desc {
    text-align: center;
    color: #495172;
    padding-top: 10px;
    padding-bottom: 3px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
}

.sensor-card-stats {
    font-family: 'Inter', sans-serif;
}

.page-header {
    padding: 7px 7px 7px 15px;
    font-family: 'Inter', sans-serif;
    /* background: #edf5fd; */
    border: none;
}

.page-custom-header {
    font-family: 'Inter', sans-serif;
    /* background: #EDF5FD; */
    border: none;
    margin-bottom: 15px;
}

.sensor-page-header {
    margin-top: 15px;
    padding: 7px 7px 7px 15px;
    font-family: 'Inter', sans-serif;
    background: #ffffff;
    border: none;
    box-shadow: 0 3px 6px 0 #0000001a;
    border-radius: 4px;
}

.radio-group {
    margin-top: 15px;
}

.logo {
    text-align: center;
}

.rpm {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.7;
    background: #eee;
    color: #333;
}

.form-group,
.form-group label,
.form-group input {
    width: 100%;
    margin-bottom: 0.5rem;
}

.form-group label {
    font-size: 0.8rem;
}

.form-group input {
    padding: 10px 20px;
    border: none;
    border-bottom: 3px solid transparent;
    background-color: #eee;
    outline-width: 0;
    font-size: 1rem;
}

.form-group input:focus {
    border-bottom: 3px solid #108ee9;
}

.btn {
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    border: none;
}

.btn:hover {
    opacity: 0.8;
}

.btn-primary {
    background-color: #3951a3;
    color: #fff;
    border: 'none'
}

.error-message {
    width: 100%;
    display: inline-block;
    padding: 5px;
    background: red;
    color: #fff;
    text-align: center;
    margin: 0.5rem 0;
}

.success-message {
    width: 100%;
    display: inline-block;
    padding: 5px;
    background: green;
    color: #fff;
    text-align: center;
    margin: 0.5rem 0;
}
