@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

.tagline {
    padding: 5rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-size: 30px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.partners {
    text-align: center;
    width: 100%;
}

.partners:hover {
    transform: scale(1.1);
    text-decoration: none;
}

.img-container {
    display: flex;
}

.img-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 100%;
    min-height: 100%;
}

.form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.form-container {
    margin: 100px auto;
    width: 1000px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    border-radius: 10px;
    height: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .form-content-left {
    background-color: #3A519F;
    color: white;
    border-radius: 10px 0 0 10px;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .form-content-right {
    border-radius: 0 10px 10px 0;
    position: relative;
    background-color: white;
    font-family: 'Inter', sans-serif;
    padding: 50px;
  }

  h1 {
    font-weight: 600;
    text-align: center;
    padding: 35px;
  }